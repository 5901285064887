import React from "react"
import styled from "styled-components"
import Seo from "components/controller/seo"

const Wrapper = styled.div`
  min-height: 70vh;
  width: 100%;
  color: black;
  background: ${props => props.theme.colors.yellow};
  font-family: "Arimo", sans-serif;
  text-align: center;

  padding: 50px 0 50px;
  @media (min-width: 700px) {
    padding: 50px 0 50px;
  }
`

const H1 = styled.h1`
  text-align: center;
  font-family: "Merriweather", serif;
  font-weight: bold;
  font-size: 48px;
`

const Closed = () => (
  <Wrapper>
    <Seo title="Temporarily Closed" />
    <H1>Torchlight Press is Temporarily Closed</H1>
    <p>Due to personal reasons, Torchlight Press will be halting operations. At the moment, this is temporary, however it may become a permanent closure.</p>
    <p>The shop is closed and no customers will be charged for any subscriptions.</p>
  </Wrapper>
)

export default Closed
